.radio-button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
}

.radio-button-group {
    padding-top: 10px;
}

span {
    white-space:pre-line;
}